@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400;1,600&display=swap');

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #474747;
    font-family: 'Poppins', sans-serif;
}

.app-container {

    .top {
        display: flex;
        align-items: center;

        .logo {
            height: 60px;
            margin: 1rem 2rem;
        }

        h1 {
            margin-left: 1rem;
            color: white;
            font-size: 1.5rem;
        }
    }

    .main {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80vh;
        width: 100%;
        

        .input-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            width: 50%;
            height: 300px;

            .input-box {
                background-color: #8D8D8D;
                border-radius: 15px;
                padding: 2rem;
                position: relative;

                img {
                    height: 50px;
                    width: 50px;
                } 

                input {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                }

                input:hover { cursor: pointer; }
            }

            .processing-container {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                flex-direction: column;

                img {
                    height: 500px;
                    object-fit: cover;
                    object-position: center;
                }

                img:hover + .input-box{
                    transition: .1s;
                    opacity: 1;
                }

                p {
                    color: white;
                    font-style: italic;
                }

                & .input-box {
                    position: absolute;
                    background-color: #8d8d8d8e;
                    border-radius: 15px;
                    padding: 2rem;
                    opacity: 0;

                    & img {
                        height: 50px;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                & .input-box:hover {
                    transition: .1s;
                    opacity: 1;
                }

            }
            
        }

        .sep {
            height: 40%;
            background-color: white;
            width: 2px;
            margin: 0 5rem;
        }

        .old-scan-container {
            width: 50%;
            
            .old-scan-box {
                height: 70px;
                width: 70%;
                border-radius: 10px;
                margin-bottom: 20px;
                background-color: #869f92a8;
                -webkit-box-shadow: 10px 10px 5px -5px rgba(255,255,255,0.24);
                -moz-box-shadow: 10px 10px 5px -5px rgba(255,255,255,0.24);
                box-shadow: 10px 10px 5px -5px rgba(255,255,255,0.24);
                
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding: 1rem 1.5rem;
                
                
               .pics-part {
                    position: relative;
                    height: 100%;

                    .old-pic {
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        margin-right: 1rem;
                    }

                    .new-pic {
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        margin-right: 1rem;
                    }
               }


                .info-container {
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-weight: 600;
                        color: white;  
                        margin: 0;
                    }

                    .date-container {
                        p {
                            font-style: italic;
                            color: white;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .projects, .members, .executables {
        padding: 0 3rem 2rem;
        color: white;

        h2 {
            font-style: italic;
            text-underline-offset: 10px;
            text-decoration: underline;
        }

        p {
            text-align: justify;
            text-justify: inter-word;
        }

        .link{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin: 0 10vw;
        }

        .links_containers {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5vh 2vw;
        }

        .links_icons{
            width: 110px;
        }

        .link_name {
            font-size: 1.2em;
            text-align: center;
            width: max-content;
        }

        .members-pics-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;
            margin-top: 3rem;

            & .sep {
                width: 5%;
                height: 1px;
                background: gray;
                margin: 3rem 0;
            }

            .members-pics-box {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                width: 60%;
                
                & .pics-container {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                img {
                    height: 200px;
                    width: 200px;
                    border-radius: 100px;
                    object-fit: cover;
                    object-position: center;
                    margin: 1rem 3rem;
                }
                
                .sub-info {
                    flex: 1;

                    p {
                        font-weight: 600;
                        font-style: italic;
                        text-align: center;
                        margin: 0;
                    }


                    p:nth-of-type(2) {
                        font-weight: 400;
                        font-style: italic;
                        color: rgb(200, 200, 200);
                    }
                }

            }

            .hidden-text {
                color: gray;
                margin-top: 1.5rem!important;
                border: 1px solid gray;
                padding: 5px;
                font-weight: 400!important;
                font-style: italic;
            }            
        }


        .project-section {
            display: flex;
            flex-direction: row;
            margin: 10rem 0 7rem;

            & .img-container {
                position: relative;
                margin-right: 5rem;
                margin-left: 5rem;
                flex: 1;
            
                & img {
                    width: 250px;
                    object-fit: cover;
                    object-position: center;
                    border: 2px solid white;
                }
            }

            & p {
                flex: 2;
                padding-right: 2rem;
            }

            & .img-container.left img {
                transform: rotate(-20deg);
            }
            
            & .img-container.right img {
                transform: rotate(10deg);
            }
        }

        .project-section:first-of-type {
            background: #639B7F;
            margin-bottom: 10rem;
        }
        
    }
}

@media screen and (max-width: 1330px) {
    .app-container .main {
        flex-direction: column;
        .input-container {
            margin-bottom: 2rem;
        }

        .old-scan-container .old-scan-box {
            max-width: 100%;
            width: 100%;
        }
    }

    .sep { display: none; }
}

@media screen and (max-width: 1000px) {
    .app-container .projects .project-section {
        flex-direction: column;
        background: none!important;
        margin-top: 3rem;
        margin-bottom: 0!important;

        & .img-container {
            margin-bottom: 5rem;
            transform: none;
            margin: 0 auto;
        }

        & .img-container {
            margin-top: 5rem;
        }

        & p {
            padding: 0rem;
        }
    }

    .app-container .projects .project-section:first-of-type {
        flex-direction: column-reverse;
    }
    .app-container .members .members-pics-container .members-pics-box {
        width: 80%;
    }
}


@media screen and (max-width: 800px) {
    .app-container .projects, .members, .executables {
        .links_containers{
            flex-direction: column;
        }
    }

    .app-container .members .members-pics-container .members-pics-box {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .app-container .top h1 {
        margin-left: 0;
        font-size: 1.2rem;

        span { display: none; } }
    .app-container .main {
        width: 90%;
        margin: 0 auto;

        .old-scan-container {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        .input-container {
            width: 100%;

            .processing-container {
                
                & img {
                    height: auto;
                    width: 80%;
                }

                & .input-box {
                    width: 50px;
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & img {
                        height: 30px;
                        width: 30px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
    }

    .app-container .members .members-pics-container .members-pics-box img {
        height: 150px;
        width: 150px;
    }

    .app-container .executables, .app-container .members, .app-container .projects {
        padding: 0 1.5rem 2rem;
        .links_containers{
            flex-direction: column;
        }
    }


    .app-container .members .members-pics-container {
        .members-pics-box {
            flex-direction: column;
            margin-bottom: 3rem;
        }
        .members-pics-box.rev {
            flex-direction: column-reverse;
        }   
    }

    

}